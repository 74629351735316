










































































































































































































































































































































































































































































































.main_wrapper {
  width: 90%;
  padding: 0 3rem;
  margin-bottom: 1rem;
  .pre_btn {
    background-color: #1dc7ea;
    border-color: #1dc7ea;
  }
  option.sel {
    padding: 5px;
    border: 1px solid #e5dada !important;
    background: red !important;
    margin-top: 20px !important;
    // .el-select-dropdown__list
  }
}

.select_boxes_items {
  text-align: right;
  margin-right: 10px;
}
