















































































































.main_charts_wrapper {
  display: flex;
  justify-content: space-between;
  margin-bottom: 2rem;
  width: 100%;
  padding: 10px;
  .part {
    width: 48%;
  }
}
